import Error from "next/error";
import React from "react";

const NotFound = () => {
  // Do not record an exception in Sentry for 404. (This is opinionated.)
  return (
    <Error statusCode={404} />
  );
};

export default NotFound;